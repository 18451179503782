import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home.css';





import insta from '../../assets/images/Instagram.png';
import tiktok from '../../assets/images/Tiktok.png';
import { ReactComponent as RightArrow } from '../../assets/images/svg/right_arrow_icn.svg';
import mixpanel from 'mixpanel-browser';





const Home = () => {
  const [profileData, setProfileData] = useState({});
  const [userBio, setUserBio] = useState({});
  const [productData, setProductData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [customLinks, setCustomLinks] = useState([]);
  const [libVisit, setLibVisit] = useState(false);
  const { user } = useParams();
  const mixpanelTrackedRef = useRef(false);
  const productClickedRef = useRef(new Set());
  const [dataFetched, setDataFetched] = useState(false);

 
  function getBackgroundColorClass(backgroundColor) {
    switch (backgroundColor) {
      case 'B549DC':
        return 'background-shadow-mulberry-violet';
      case 'EB43A8':
        return 'background-shadow-raspberry-pink';
      case 'D5F84C':
        return 'background-shadow-sparkle-lime';
      case '6AE9D3':
        return 'background-shadow-mint-soda';
      case '45464E':
        return 'background-shadow-cool-charcoal';
      case 'F75B6D':
        return 'background-shadow-jam-red';
      case 'F7C955':
        return 'background-shadow-juicy-orange';
      case '59E4F6':
        return 'background-shadow-pure-sky';
      default:
        return '';
    }
  }
  

  useEffect(() => {
    const fetchData = async () => {
      if (!dataFetched) {
        try {
          const response = await axios.get(`https://dev.pixellapi.com/api/get_lib/${user}`);
          const data = response.data.data;
          setProfileData(data);
          setUserBio(data.user);
          setProductData(data.products);

          // Filter links with link_type 'custom_link'
          const filteredLinks = data.links.filter(link => link.link_type === 'custom_link');
          setCustomLinks(filteredLinks);

          setDataFetched(true);
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }
    };

    fetchData();
  }, [user, dataFetched]);

  useEffect(() => {
    if (!mixpanelTrackedRef.current) {
      // Mixpanel initialization and tracking
      mixpanel.init("020bc8bb0f15b3e7e4b82996eea837af", { debug: true});
      mixpanel.track("LIB Visited", {
        "lib_name": user 
      });
      mixpanelTrackedRef.current = true;
      console.log(libVisit);

    }
  }, [user, libVisit]);
      

  const settings = {
    centerMode: true,
    centerPadding: '10%',
    slidesToShow: 1,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveIndex(next),
  };


  const handleProductMouseDown = (event, productUrl) => {
    if (!event.defaultPrevented) {
      mixpanel.track("LIB Product Clicked", {
        "lib_name": user,
        "product_url": productUrl
      });
      event.preventDefault();
    }
  };


  return (
    <div className="home">
      <Container style={{ padding: '0' }}>
        <div className="main ">
          {productData.length === 0 ? (
            <p className="text-light fs-4">No User Found</p>
          ) : (
            <div className="mains">
              <div className="profile-section" style={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}>
                <div className="shadow-box" style={{ backgroundImage: `url(${profileData.background_cover})` }}>
                <div className={`${getBackgroundColorClass(profileData.background_color)}`}>

                    <div className="shadow-data">
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="social-links-area" style={{ width: "100%", display: "flex", marginTop:16, paddingRight: 16, justifyContent: "flex-end" }}>
                        <div className="d-flex justify-content-center align-items-center">
                          {userBio.social_account && userBio.social_account.includes('ig') ? (
                            <a href={`https://www.instagram.com/${userBio.primary_username}`} target="_blank" rel="noopener noreferrer">
                              <img src={insta} width={42} alt="" />

                            </a>
                          ) : (
                            <a href={`https://www.tiktok.com/@${userBio.primary_username}`} target="_blank" rel="noopener noreferrer">
                              <img src={tiktok} width={42} alt="" />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bio-text-area" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 104 }}>
                      <div>
                        <p className="font-head-1">{profileData.name || ''}</p>
                        <p className="font-head-2">{profileData.bio || ''}</p>
                      </div>
                    </div>
                  </div>
                        </div>
                  {/* <img src={getShadowImage()} className="shadow-image" alt="" /> */}
   
                </div>
              </div>

              <div className="slider-section">
                {customLinks.length > 1 && (
                  <Slider {...settings}>
                    {customLinks.map((item, index) => (
                      <div key={index} className={`slider-item ${index === activeIndex ? 'active' : ''}`}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <a key={index} href={item.url} target="_blank" rel="noopener" style={{ width: "100%", textDecoration: "none" }}>
                            <p className="slider-title">{item.title || ''}</p>
                          </a>
                          <RightArrow height={24} />
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>

              <div className="products-section">
                <Container >
                  <p className="p-products-header">My Favorites</p>
                  <Row style={{ marginTop: 16 }}>
                    {productData.map((item, index) => (
    <Col
    key={index}
    xs={6}
    sm={6}
    md={6}

    style={{
      marginBottom: 16,
      paddingLeft: index % 2 === 0 ? 12 : 6, // Reduce left padding for even index (left column)
      paddingRight: index % 2 === 0 ? 6 : 12, // Reduce right padding for odd index (right column)
    }}
  >
                        <div className=" " style={{ marginTop: 0 }}>
                          {item.product_url ? (
                            <a href={item.product_url} target="_blank" rel="noopener" onMouseDown={(event) => handleProductMouseDown(event, item.product_url)}>
                              <img src={item.images[0].image_url} className="img-product" style={{ margin: 0 }} alt="" />
                            </a>
                          ) : (
                            <img src={item.images[0].image_url} className="img-product" alt="" />
                          )}
                          <div className="product-desc">
                            <p className="p-product-name">{item.product_name}</p>
                            <p className="p-product-price">${item.product_price.toFixed(2)}</p>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Home; 