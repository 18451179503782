import './App.css';
import RouterData from './RouterData/RouterData';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <RouterData />
    </>
  );
}

export default App;
