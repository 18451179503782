import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from "../Pages/Home/Home"

function RouterData() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<Home />} />
                <Route path='/:user' element={<Home />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RouterData